<template>
  <div>
    <v-card v-if="loading" loading>
      <v-card-title>Loading...</v-card-title>
    </v-card>
    <v-card v-else>
      <v-card-title>{{ deployment.name }}</v-card-title>
      <v-list>
        <v-subheader>Deployments</v-subheader>
        <v-list-group
          v-for="(deploy, deployId) in deployments"
          :key="deployId"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ deploy.ref }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="currentImage(deploy.ref)">
                {{ currentImage(deploy.ref).imageHash }} built {{ currentImage(deploy.ref).current | momentFromNow }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(build, buildId) in currentImage(deploy.ref).builds"
            :key="`${deployId}-${buildId}`"
            @click="build.current ? buildDeploy = false : deployBuild(deploy, build)"
          >
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ build.tag }} built {{ build.timestamp | momentFromNow }}
                <span v-if="build.current">(current)</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>

    <v-dialog
      :value="buildDeploy !== false"
      max-width="290"
      @click:outside="buildDeploy = false"
      @keydown="buildDeploy = false"
    >
      <v-card>
        <v-card-title class="headline">Deploy Build</v-card-title>

        <v-card-text v-if="buildError" color="red">
          There was a problem deploying this build.
        </v-card-text>

        <v-card-text v-else-if="buildDeploying">
          Deploying build...
        </v-card-text>

        <v-card-text v-else-if="buildDeploy">
          Are you sure you want to deploy {{ buildDeploy.build.tag }} to {{ buildDeploy.deployment.ref }}?
        </v-card-text>

        <v-card-actions v-if="!buildDeploying">
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="buildDeploy = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="actionDeployBuild"
          >
            Deploy
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="error"
      color="error"
      multi-line
      bottom
    >
      {{ errorMsg }}
      <v-btn
        text
        @click="error = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { db } from '../../../firestore';
import kubeDeployment from '../../../model/kubernetes/Deployment';
import kubeImage from '../../../model/kubernetes/Image';

export default {
  name: 'DeployerDetail',
  data() {
    return {
      buildDeploy: false,
      buildDeploying: false,
      buildError: false,
      deployment: null,
      deployments: [],
      error: false,
      errorMsg: '',
      kubeDeployments: null,
      kubeImages: {},
      loading: true,
    };
  },
  computed: {
    deploymentID() {
      return this.$route.params.deploymentID;
    },
  },
  methods: {
    actionDeployBuild() {
      const KubeDeployment = new kubeDeployment();
      this.buildDeploying = true;
      KubeDeployment.deployBuild(
        this.buildDeploy.deployment.ref,
        `${this.buildDeploy.build.imageRef}:${this.buildDeploy.build.tag}`
      ).then(() => {
        this.loadDeployments();
      }).catch(() => {
        this.buildError = true;
      });
    },
    currentImage(ref) {
      if (!this.kubeImages[ref]) return false;
      const { imageRef, imageHash, images } = this.kubeImages[ref];
      const [ imageHashType ] = imageHash.split('-');
      let current = false;
      const builds = [];
      for (const image of images) {
        if (image.tag === imageHash) {
          current = new Date(image.timestamp);
          image.current = true;
        }
        const [ imageType ] = image.tag.split('-');
        image.imageRef = imageRef;
        if (imageType === imageHashType) {
          builds.push(image);
        }
      }
      return { imageHash, current, builds };
    },
    deployBuild(deployment, build) {
      this.buildDeploy = { deployment, build };
      this.buildDeploying = false;
      this.buildError = false;
    },
    loadDeployments() {
      this.loading = true;
      const KubeDeployment = new kubeDeployment();
      const KubeImage = new kubeImage();
      KubeDeployment.getDeployments().then(async (d) => {
        this.kubeDeployments = d;
        for (const deploy of this.deployments) {
          for (const kd of this.kubeDeployments) {
            if (kd.metadata.name === deploy.ref) {
              const image = kd.spec.template.spec.containers[0].image;
              const [ imageRef, imageHash ] = image.split(':');
              try {
                const images = await KubeImage.getImageTags(imageRef);
                this.kubeImages[deploy.ref] = {
                  imageRef,
                  imageHash,
                  images: images.tags
                }
              } catch (e) {
                this.errorMsg = `Failed to pull deployment images for ref ${imageRef}`;
              }
            }
          }
        }
        this.buildDeploy = false;
        this.loading = false;
      }).catch(() => {
        this.errorMsg = `Failed to pull kubernetes deployments`;
      });
    },
  },
  mounted() {
    db.collection('shepherd-deployment')
      .doc(this.deploymentID)
      .get()
      .then((deployment) => {
        if (!deployment.exists) {
          this.$route.push({ name: 'deployer-list'});
        } else {
          this.deployment = deployment.data();
          db.collection('shepherd-deployment')
            .doc(this.deploymentID)
            .collection('deployment')
            .get()
            .then((deployments) => {
              deployments.docs.forEach(d => this.deployments.push(d.data()));
            });
        }
      });
    this.loadDeployments();
  },
  watch: {
    errorMsg() {
      this.error = (this.errorMsg !== '');
    }
  },
};
</script>

